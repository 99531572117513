var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContentContainer',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',[_vm._v("Timelines")])]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"dropdown mr-3"},[_c('a',{ref:"timeline_status_filter",staticClass:"btn btn-light dropdown-toggle",attrs:{"disabled":_vm.loading,"href":"#","role":"button","id":"dropdownMenuLink","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v("Filter By Status: "+_vm._s(_vm.timelineStatusFilters[_vm.statusFilter]))]),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuLink"}},[(
              _vm.timelineStatusFilters &&
                Object.keys(_vm.timelineStatusFilters).length > 1
            )?_vm._l((Object.keys(_vm.timelineStatusFilters)),function(timelineStatus){return _c('li',{key:timelineStatus},[_c('a',{staticClass:"dropdown-item",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.searchTimelines(timelineStatus)}}},[_vm._v(_vm._s(_vm.timelineStatusFilters[timelineStatus]))])])}):_vm._e()],2)]),_c('button',{staticClass:"btn-blue",on:{"click":() => {
            _vm.$bvModal.show('createNewTimeline');
          }}},[_vm._v(" Add New Timeline ")])])]},proxy:true}])},[_c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.cancelUnstartedTimeline",modifiers:{"cancelUnstartedTimeline":true}}],staticClass:"w-100 mx-auto",attrs:{"id":"cancelUnstartedTimeline","variant":"light","dismissible":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('button',{staticClass:"btn-outline-blue",on:{"click":() => {
              _vm.cancelTimelineObj = null;
              _vm.$bvModal.hide('cancelUnstartedTimeline');
            }}},[_vm._v(" Close Modal ")]),(_vm.cancelTimelineObj && _vm.cancelTimelineObj.current_action_id == 0)?_c('button',{staticClass:"btn-blue",on:{"click":() => {
              _vm.$bvModal.hide('cancelUnstartedTimeline');
              _vm.cancelTimeline();
            }}},[_vm._v(" Cancel Timeline ")]):_vm._e()])]},proxy:true}])},[_c('div',{attrs:{"slot":"modal-header"},slot:"modal-header"},[_vm._v("Cancel Unstarted Timeline:")]),(_vm.cancelTimelineObj && _vm.cancelTimelineObj.current_action_id == 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Are you sure you wish to "+_vm._s(_vm.cancelTimelineObj.consumer.consumer_email)+"'s "+_vm._s(_vm.cancelTimelineObj.workflow_name)+" timeline? It has not started yet ")]):_c('div',{staticClass:"text-center"},[_vm._v(" This timeline cannot be cancelled, please close this window ")])]),_c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.createNewTimeline",modifiers:{"createNewTimeline":true}}],staticClass:"w-100 mx-auto",attrs:{"id":"createNewTimeline","variant":"light","dismissible":""}},[_c('div',{attrs:{"slot":"modal-header"},slot:"modal-header"},[_vm._v("Create New Timeline:")]),(!_vm.workflows || _vm.workflows.length == 1)?[_c('div',{staticClass:"text-center"},[_vm._v(" You can not create a timeline without first finalizing a workflow. ")]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('button',{staticClass:"btn-sm btn-outline-danger mx-2",on:{"click":() => {
              _vm.$bvModal.hide('createNewTimeline');
            }}},[_vm._v(" Close Modal ")])])]:[(
          Array.isArray(_vm.newTimelineData.consumerSelectionOptions) &&
            _vm.newTimelineData.consumerSelectionOptions.length > 0
        )?[_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',{attrs:{"sm":"12"}},[_c('label',{staticClass:"h6"},[_vm._v("Consumer Options")]),_c('b-form-select',{staticClass:"b-select",attrs:{"options":_vm.newTimelineData.initializationConsumerOptions},on:{"change":() => {
                  _vm.newTimelineData.consumerSelected = null;
                  _vm.initializeTimelineSetConsumer();
                }},model:{value:(_vm.newTimelineData.consumersAvailable),callback:function ($$v) {_vm.$set(_vm.newTimelineData, "consumersAvailable", $$v)},expression:"newTimelineData.consumersAvailable"}})],1)],1),(_vm.newTimelineData.consumersAvailable)?_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',{attrs:{"sm":"12"}},[_c('label',{staticClass:"h6"},[_vm._v("Select Consumer")]),_c('b-form-select',{staticClass:"b-select",attrs:{"options":_vm.newTimelineData.consumerSelectionOptions},on:{"change":_vm.initializeTimelineSetConsumer},model:{value:(_vm.newTimelineData.consumerSelected),callback:function ($$v) {_vm.$set(_vm.newTimelineData, "consumerSelected", $$v)},expression:"newTimelineData.consumerSelected"}})],1)],1):_vm._e()]:_vm._e(),(
          !Array.isArray(_vm.newTimelineData.consumerSelectionOptions) ||
            _vm.newTimelineData.consumerSelectionOptions.length == 0 ||
            _vm.newTimelineData.consumerSelected ||
            !_vm.newTimelineData.consumersAvailable
        )?[_c('label',{staticClass:"h6"},[_vm._v("Workflow"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-select',{attrs:{"options":_vm.workflows},model:{value:(_vm.form.workflowUUID),callback:function ($$v) {_vm.$set(_vm.form, "workflowUUID", $$v)},expression:"form.workflowUUID"}}),_c('label',{staticClass:"h6"},[_vm._v("Consumer E-mail"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.consumerEmail),expression:"form.consumerEmail"}],staticClass:"form-control w-100",attrs:{"type":"text","placeholder":"E-mail","readonly":_vm.newTimelineData.consumerSelected ? true : false},domProps:{"value":(_vm.form.consumerEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "consumerEmail", $event.target.value)}}}),_vm._l((_vm.metaDisplayOrder),function(metaName){return [_c('b-row',{key:metaName,staticClass:"striped_form_row mb-2 mt-2"},[_c('b-col',{attrs:{"sm":"12"}},[_c('label',{staticClass:"float-left ml-2 h6"},[_vm._v(_vm._s(_vm.getClientInContext.consumer_meta[metaName].name || metaName)),(_vm.getClientInContext.consumer_meta[metaName].required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]),(
                _vm.getClientInContext.consumer_meta[metaName].type == 'date' ||
                  _vm.getClientInContext.consumer_meta[metaName].type ==
                    'date + time'
              )?[_c('b-col',{attrs:{"sm":_vm.getClientInContext.consumer_meta[metaName].type == 'date'
                    ? '12'
                    : '6'}},[_c('b-form-datepicker',{staticStyle:{"height":"100%"},attrs:{"required":_vm.getClientInContext.consumer_meta[metaName].required,"size":"sm","locale":"en","placeholder":"mm/dd/yyyy"},model:{value:(_vm.form.consumerMeta[metaName].value),callback:function ($$v) {_vm.$set(_vm.form.consumerMeta[metaName], "value", $$v)},expression:"form.consumerMeta[metaName].value"}})],1),(
                  _vm.getClientInContext.consumer_meta[metaName].type ==
                    'date + time'
                )?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-timepicker',{staticClass:"time-picker",attrs:{"reqiured":_vm.getClientInContext.consumer_meta[metaName].required,"locale":"en","placeholder":"00:00:00"},model:{value:(_vm.form.consumerMeta[metaName].timeValue),callback:function ($$v) {_vm.$set(_vm.form.consumerMeta[metaName], "timeValue", $$v)},expression:"form.consumerMeta[metaName].timeValue"}})],1):_vm._e()]:(
                _vm.getClientInContext.consumer_meta[metaName].type ==
                  'currency' ||
                  _vm.getClientInContext.consumer_meta[metaName].type == 'number'
              )?_c('b-col',{attrs:{"sm":"12"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.consumerMeta[metaName].value),expression:"form.consumerMeta[metaName].value"}],staticClass:"form-control",attrs:{"type":"number","required":_vm.getClientInContext.consumer_meta[metaName].required},domProps:{"value":(_vm.form.consumerMeta[metaName].value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.consumerMeta[metaName], "value", $event.target.value)}}})]):_c('b-col',{attrs:{"sm":"12"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.consumerMeta[metaName].value),expression:"form.consumerMeta[metaName].value"}],staticClass:"form-control",attrs:{"type":"text","required":_vm.getClientInContext.consumer_meta[metaName].required},domProps:{"value":(_vm.form.consumerMeta[metaName].value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form.consumerMeta[metaName], "value", $event.target.value)}}})])],2)]}),_c('b-row',[_c('b-col',{staticClass:"text-left small",attrs:{"xs":"12"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" = Required ")])],1)]:_vm._e(),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('button',{staticClass:"btn-sm btn-blue btn-outline-dark mx-2",on:{"click":() => {
              _vm.createTimeline();
            }}},[_vm._v(" Create Timeline ")]),_c('button',{staticClass:"btn-sm btn-outline-danger mx-2",on:{"click":() => {
              _vm.resetForm();
              _vm.$bvModal.hide('createNewTimeline');
            }}},[_vm._v(" Cancel ")])])]],2),(_vm.loading)?_c('div',{staticClass:"text-center m-4"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1):_c('div',[(
        Array.isArray(_vm.timelinesDisplayed) && _vm.timelinesDisplayed.length > 0
      )?[_c('div',{staticClass:"display-table"},_vm._l((_vm.timelinesDisplayed),function(timeline,i){return _c('b-card',{key:i},[_c('b-row',{staticClass:"display-table-row"},[_c('b-col',[(!timeline.completed && timeline.in_progress == 1)?_c('i',{staticClass:"far fa-check-circle text-primary mx-2"}):_vm._e(),(timeline.in_progress == 0 && timeline.completed == 0)?_c('i',{staticClass:"fas fa-ban text-danger mx-2"}):_vm._e(),(timeline.completed)?_c('i',{staticClass:"fas fa-check-circle text-success mx-2"}):_vm._e(),_vm._v(_vm._s(timeline.workflow_name))]),_c('b-col',[_c('b-col',{staticClass:"assigned-to"},[(timeline.consumer.consumer_email)?_c('div',[_c('span',[_vm._v("Assigned to ")]),_c('strong',[_vm._v(_vm._s(timeline.consumer.consumer_email))])]):_c('span',[_vm._v("No assigned consumers")])])],1),_c('b-col',[(timeline.unsubscribed)?_c('span',{staticClass:"text-danger"},[_vm._v("Unsubscribed")]):_c('span',{staticClass:"text-success"},[_vm._v("Subscribed")])]),(timeline.current_action_id > 0)?_c('b-col',{staticClass:"button-col"},[_c('button',{staticClass:"btn-blue w-100",on:{"click":function($event){return _vm.setTimeline(timeline)}}},[_vm._v(" View Timeline ")])]):[(timeline.in_progress)?[_c('b-col',[_vm._v(" Timeline starts "+_vm._s(new Date( timeline.scheduled_start_datetime ).toLocaleString())+" ")]),_c('b-col',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.cancelUnstartedTimeline",modifiers:{"cancelUnstartedTimeline":true}}],staticClass:"btn-danger button-col",on:{"click":() => {
                        _vm.cancelTimelineObj = timeline;
                        _vm.$bvModal.show('cancelUnstartedTimeline');
                      }}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Cancel Timeline ")])])]:[_c('p',[_vm._v(" Timeline cancelled "+_vm._s(new Date(timeline.last_update_datetime).toLocaleString())+" ")])]]],2)],1)}),1),_c('div',{staticClass:"d-flex w-100 mt-3"},[_c('ul',{staticClass:"pagination mx-auto"},[(_vm.pagination.page > 1)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setPage(_vm.pagination.page - 1)}}},[_vm._v("Previous")])]):_vm._e(),_vm._l((_vm.paginationRange(
              1,
              Math.min(_vm.pagination.totalPages, 4)
            )),function(i){return [_c('li',{key:'pagination_top_' + i,class:'page-item' + (_vm.pagination.page == i ? ' active' : '')},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setPage(i)}}},[_vm._v(_vm._s(i))])])]}),(_vm.pagination.totalPages > 4)?[(_vm.pagination.page - 4 > 4)?[_c('li',{staticClass:"page-item disabled"},[_c('span',{staticClass:"page-link"},[_vm._v("...")])])]:_vm._e(),_vm._l((_vm.paginationRange(
                Math.max(5, _vm.pagination.page - 3),
                Math.min(_vm.pagination.page + 3, _vm.pagination.totalPages)
              )),function(i){return [_c('li',{key:'pagination_top_' + i,class:'page-item' + (_vm.pagination.page == i ? ' active' : '')},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setPage(i)}}},[_vm._v(_vm._s(i))])])]}),(_vm.pagination.totalPages > Math.max(4, _vm.pagination.page) + 3)?[(_vm.pagination.totalPages - 4 > _vm.pagination.page + 4)?[_c('li',{staticClass:"page-item disabled"},[_c('span',{staticClass:"page-link"},[_vm._v("...")])])]:_vm._e(),_vm._l((_vm.paginationRange(
                  Math.max(_vm.pagination.totalPages - 3, _vm.pagination.page + 4),
                  _vm.pagination.totalPages
                )),function(i){return [_c('li',{key:'pagination_top_' + i,class:'page-item' + (_vm.pagination.page == i ? ' active' : '')},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setPage(i)}}},[_vm._v(_vm._s(i))])])]})]:_vm._e()]:_vm._e(),(_vm.pagination.page < _vm.pagination.totalPages)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setPage(_vm.pagination.page + 1)}}},[_vm._v("Next")])]):_vm._e()],2)])]:[_c('b-card',{staticClass:"workflow-item"},[_c('div',{staticClass:"row justify-content-center h4"},[_c('h5',[_c('strong',[_vm._v("No Timelines Found")])])])])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }