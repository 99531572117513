<template>
  <ContentContainer>
    <template #title>
      <h3>Timelines</h3>
    </template>
    <template #header>
      <div class="d-flex">
        <div class="dropdown mr-3">
          <a
            :disabled="loading"
            class="btn btn-light dropdown-toggle"
            ref="timeline_status_filter"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filter By Status: {{ timelineStatusFilters[statusFilter] }}</a
          >

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <template
              v-if="
                timelineStatusFilters &&
                  Object.keys(timelineStatusFilters).length > 1
              "
            >
              <li
                v-for="timelineStatus in Object.keys(timelineStatusFilters)"
                :key="timelineStatus"
              >
                <a
                  :disabled="loading"
                  class="dropdown-item"
                  @click="searchTimelines(timelineStatus)"
                  >{{ timelineStatusFilters[timelineStatus] }}</a
                >
              </li>
            </template>
          </ul>
        </div>
        <button
          class="btn-blue"
          @click="
            () => {
              $bvModal.show('createNewTimeline');
            }
          "
        >
          Add New Timeline
        </button>
      </div>
    </template>
    <b-modal
      v-b-modal.cancelUnstartedTimeline
      id="cancelUnstartedTimeline"
      variant="light"
      dismissible
      class="w-100 mx-auto"
    >
      <div slot="modal-header">Cancel Unstarted Timeline:</div>
      <div
        class="text-center"
        v-if="cancelTimelineObj && cancelTimelineObj.current_action_id == 0"
      >
        Are you sure you wish to
        {{ cancelTimelineObj.consumer.consumer_email }}'s
        {{ cancelTimelineObj.workflow_name }} timeline? It has not started yet
      </div>
      <div class="text-center" v-else>
        This timeline cannot be cancelled, please close this window
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <button
            class="btn-outline-blue"
            @click="
              () => {
                cancelTimelineObj = null;
                $bvModal.hide('cancelUnstartedTimeline');
              }
            "
          >
            Close Modal
          </button>
          <button
            v-if="cancelTimelineObj && cancelTimelineObj.current_action_id == 0"
            class="btn-blue"
            @click="
              () => {
                $bvModal.hide('cancelUnstartedTimeline');
                cancelTimeline();
              }
            "
          >
            Cancel Timeline
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.createNewTimeline
      id="createNewTimeline"
      variant="light"
      dismissible
      class="w-100 mx-auto"
    >
      <div slot="modal-header">Create New Timeline:</div>
      <template v-if="!workflows || workflows.length == 1">
        <div class="text-center">
          You can not create a timeline without first finalizing a workflow.
        </div>
        <div slot="modal-footer">
          <button
            class="btn-sm btn-outline-danger mx-2"
            @click="
              () => {
                $bvModal.hide('createNewTimeline');
              }
            "
          >
            Close Modal
          </button>
        </div>
      </template>
      <template v-else>
        <template
          v-if="
            Array.isArray(newTimelineData.consumerSelectionOptions) &&
              newTimelineData.consumerSelectionOptions.length > 0
          "
        >
          <b-row class="mb-2 mt-2">
            <b-col sm="12">
              <label class="h6">Consumer Options</label>
              <b-form-select
                :options="newTimelineData.initializationConsumerOptions"
                class="b-select"
                @change="
                  () => {
                    newTimelineData.consumerSelected = null;
                    initializeTimelineSetConsumer();
                  }
                "
                v-model="newTimelineData.consumersAvailable"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="newTimelineData.consumersAvailable" class="mb-2 mt-2">
            <b-col sm="12">
              <label class="h6">Select Consumer</label>
              <b-form-select
                :options="newTimelineData.consumerSelectionOptions"
                class="b-select"
                v-model="newTimelineData.consumerSelected"
                @change="initializeTimelineSetConsumer"
              ></b-form-select>
            </b-col>
          </b-row>
        </template>
        <template
          v-if="
            !Array.isArray(newTimelineData.consumerSelectionOptions) ||
              newTimelineData.consumerSelectionOptions.length == 0 ||
              newTimelineData.consumerSelected ||
              !newTimelineData.consumersAvailable
          "
        >
          <label class="h6">Workflow<span class="text-danger">*</span></label>
          <b-form-select
            :options="workflows"
            v-model="form.workflowUUID"
          ></b-form-select>
          <label class="h6"
            >Consumer E-mail<span class="text-danger">*</span></label
          >
          <input
            class="form-control w-100"
            type="text"
            v-model="form.consumerEmail"
            placeholder="E-mail"
            :readonly="newTimelineData.consumerSelected ? true : false"
          />
          <!-- TODO: MOVE THIS TO SHARED SECTION WITH CONSUMER OVERVIEW PORTION OF THIS! -->
          <template v-for="metaName in metaDisplayOrder">
            <b-row class="striped_form_row mb-2 mt-2" :key="metaName">
              <b-col sm="12">
                <label class="float-left ml-2 h6"
                  >{{
                    getClientInContext.consumer_meta[metaName].name || metaName
                  }}<span
                    v-if="getClientInContext.consumer_meta[metaName].required"
                    class="text-danger"
                    >*</span
                  ></label
                >
              </b-col>
              <template
                v-if="
                  getClientInContext.consumer_meta[metaName].type == 'date' ||
                    getClientInContext.consumer_meta[metaName].type ==
                      'date + time'
                "
              >
                <b-col
                  :sm="
                    getClientInContext.consumer_meta[metaName].type == 'date'
                      ? '12'
                      : '6'
                  "
                >
                  <b-form-datepicker
                    :required="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    size="sm"
                    locale="en"
                    v-model="form.consumerMeta[metaName].value"
                    placeholder="mm/dd/yyyy"
                    style="height:100%"
                  ></b-form-datepicker>
                </b-col>
                <b-col
                  v-if="
                    getClientInContext.consumer_meta[metaName].type ==
                      'date + time'
                  "
                  sm="6"
                >
                  <b-form-timepicker
                    class="time-picker"
                    :reqiured="
                      getClientInContext.consumer_meta[metaName].required
                    "
                    v-model="form.consumerMeta[metaName].timeValue"
                    locale="en"
                    placeholder="00:00:00"
                  ></b-form-timepicker>
                </b-col>
              </template>
              <b-col
                sm="12"
                v-else-if="
                  getClientInContext.consumer_meta[metaName].type ==
                    'currency' ||
                    getClientInContext.consumer_meta[metaName].type == 'number'
                "
              >
                <input
                  type="number"
                  :required="
                    getClientInContext.consumer_meta[metaName].required
                  "
                  v-model="form.consumerMeta[metaName].value"
                  class="form-control"
                />
              </b-col>
              <b-col sm="12" v-else>
                <input
                  type="text"
                  :required="
                    getClientInContext.consumer_meta[metaName].required
                  "
                  v-model="form.consumerMeta[metaName].value"
                  class="form-control"
                />
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col xs="12" class="text-left small">
              <span class="text-danger">*</span> = Required
            </b-col>
          </b-row>
        </template>
        <div slot="modal-footer">
          <button
            class="btn-sm btn-blue btn-outline-dark mx-2"
            @click="
              () => {
                createTimeline();
              }
            "
          >
            Create Timeline
          </button>
          <button
            class="btn-sm btn-outline-danger mx-2"
            @click="
              () => {
                resetForm();
                $bvModal.hide('createNewTimeline');
              }
            "
          >
            Cancel
          </button>
        </div>
      </template>
      <!-- END TEMPLATE -->
    </b-modal>
    <div class="text-center m-4" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <template
        v-if="
          Array.isArray(timelinesDisplayed) && timelinesDisplayed.length > 0
        "
      >
        <div class="display-table">
          <b-card v-for="(timeline, i) in timelinesDisplayed" :key="i">
            <b-row class="display-table-row">
              <b-col>
                <i
                  class="far fa-check-circle text-primary mx-2"
                  v-if="!timeline.completed && timeline.in_progress == 1"
                ></i>
                <i
                  class="fas fa-ban text-danger mx-2"
                  v-if="timeline.in_progress == 0 && timeline.completed == 0"
                ></i>
                <i
                  class="fas fa-check-circle text-success mx-2"
                  v-if="timeline.completed"
                ></i
                >{{ timeline.workflow_name }}</b-col
              >
              <b-col>
                <b-col class="assigned-to">
                  <div v-if="timeline.consumer.consumer_email">
                    <span>Assigned to </span>
                    <strong>{{ timeline.consumer.consumer_email }}</strong>
                  </div>
                  <span v-else>No assigned consumers</span></b-col
                >
              </b-col>
              <b-col>
                <span v-if="timeline.unsubscribed" class="text-danger"
                  >Unsubscribed</span
                >
                <span v-else class="text-success">Subscribed</span>
              </b-col>
              <b-col v-if="timeline.current_action_id > 0" class="button-col">
                <button class="btn-blue w-100" @click="setTimeline(timeline)">
                  View Timeline
                </button>
              </b-col>
              <template v-else>
                <template v-if="timeline.in_progress">
                  <b-col>
                    Timeline starts
                    {{
                      new Date(
                        timeline.scheduled_start_datetime
                      ).toLocaleString()
                    }}
                  </b-col>
                  <b-col>
                    <button
                      class="btn-danger button-col"
                      v-b-modal.cancelUnstartedTimeline
                      @click="
                        () => {
                          cancelTimelineObj = timeline;
                          $bvModal.show('cancelUnstartedTimeline');
                        }
                      "
                    >
                      <i class="fas fa-ban"></i> Cancel Timeline
                    </button>
                  </b-col>
                </template>
                <template v-else>
                  <p>
                    Timeline cancelled
                    {{
                      new Date(timeline.last_update_datetime).toLocaleString()
                    }}
                  </p>
                </template>
              </template>
            </b-row>
          </b-card>
        </div>
        <div class="d-flex w-100 mt-3">
          <ul class="pagination mx-auto">
            <li class="page-item" v-if="pagination.page > 1">
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setPage(pagination.page - 1)"
                >Previous</a
              >
            </li>
            <!-- pages 1 to 4 -->
            <template
              v-for="i in paginationRange(
                1,
                Math.min(pagination.totalPages, 4)
              )"
            >
              <li
                :class="'page-item' + (pagination.page == i ? ' active' : '')"
                :key="'pagination_top_' + i"
              >
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  @click="setPage(i)"
                  >{{ i }}</a
                >
              </li>
            </template>
            <template v-if="pagination.totalPages > 4">
              <template v-if="pagination.page - 4 > 4">
                <li class="page-item disabled">
                  <span class="page-link">...</span>
                </li>
              </template>
              <!-- pages ~5/page - 3 to maxPage / page + 3 -->
              <template
                v-for="i in paginationRange(
                  Math.max(5, pagination.page - 3),
                  Math.min(pagination.page + 3, pagination.totalPages)
                )"
              >
                <li
                  :class="'page-item' + (pagination.page == i ? ' active' : '')"
                  :key="'pagination_top_' + i"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    @click="setPage(i)"
                    >{{ i }}</a
                  >
                </li>
              </template>
              <template
                v-if="pagination.totalPages > Math.max(4, pagination.page) + 3"
              >
                <template
                  v-if="pagination.totalPages - 4 > pagination.page + 4"
                >
                  <li class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>
                </template>
                <template
                  v-for="i in paginationRange(
                    Math.max(pagination.totalPages - 3, pagination.page + 4),
                    pagination.totalPages
                  )"
                >
                  <li
                    :class="
                      'page-item' + (pagination.page == i ? ' active' : '')
                    "
                    :key="'pagination_top_' + i"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="setPage(i)"
                      >{{ i }}</a
                    >
                  </li>
                </template>
              </template>
            </template>
            <li
              class="page-item"
              v-if="pagination.page < pagination.totalPages"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setPage(pagination.page + 1)"
                >Next</a
              >
            </li>
          </ul>
        </div>
      </template>
      <template v-else>
        <b-card class="workflow-item">
          <div class="row justify-content-center h4">
            <h5><strong>No Timelines Found</strong></h5>
          </div>
        </b-card>
      </template>
    </div>
  </ContentContainer>
</template>
<script>
import { mapGetters } from "vuex";
import ContentContainer from "@/components/templates/ContentContainer";

export default {
  components: { ContentContainer },
  data() {
    return {
      loading: false,
      // statusFilter = default filter.
      statusFilter: "in_progress",
      timelineStatusFilters: {
        all: "All",
        in_progress: "In Progress",
        incomplete: "Incomplete",
        completed: "Complete",
      },
      pagination: {
        page: 0,
        totalPages: 0,
        limit: 100,
      },
      cancelTimelineObj: null,
      timelines: null,
      timelinesDisplayed: null,
      isSubmitting: false,
      workflows: [{ value: null, text: "Select workflow" }],
      form: {
        workflowUUID: null,
        consumerEmail: null,
        consumerMeta: {},
      },
      metaDisplayOrder: [],
      consumerMeta: null,
      newTimelineData: {
        consumersAvailable: false,
        initializationConsumerOptions: [
          { text: "Use Existing Consumer", value: true },
          { text: "Create New Consumer", value: false },
        ],
        consumerSelected: false,
        consumers: [],
        consumerSelectionOptions: [],
      },
      client: null,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      if (!this.getClientInContext) {
        const clientID = this.$route.params.clientID;
        this.client = await this.$store.dispatch("getClientByID", clientID);

        if (!this.client) {
          this.returnToDashboard();
          return;
        }
      }
      await this.$bus.$emit("breadcrumbData", [
        {
          text: "Timelines",
          to: {
            name: "ClientTimelines",
          },
        },
      ]);
      await this.$store.commit("setResetBaselineComponent");
      // reset form.
      this.form = {
        workflowUUID: null,
        consumerEmail: null,
        consumerMeta: {},
      };
      // get sorted order of the metadata for display.
      if (this.getClientInContext.consumer_meta) {
        let self = this;
        let metaKeys = Object.keys(this.getClientInContext.consumer_meta).sort(
          function(a, b) {
            return (
              (self.getClientInContext.consumer_meta[a].displayOrder || 0) -
              (self.getClientInContext.consumer_meta[b].displayOrder || 0)
            );
          }
        );
        this.metaDisplayOrder = metaKeys;
        // store this as form models to bind
        this.resetForm();
      } else {
        this.metaDisplayOrder = [];
      }

      await this.$store.dispatch("getClientConsumers", {
        forceFetch: false,
        sort: undefined,
      });
      this.newTimelineData.consumers = this.getClientConsumerData;
      if (
        Array.isArray(this.newTimelineData.consumers) &&
        this.newTimelineData.consumers.length > 0
      ) {
        // set default
        this.initializeTimelineWithExistingConsumer = true;
        for (let i = 0; i < this.newTimelineData.consumers.length; i++) {
          let consumerDisplay = (
            (this.newTimelineData.consumers[i].meta["firstName"] || "") +
            " " +
            (this.newTimelineData.consumers[i].meta["lastName"] || "") +
            " <" +
            this.newTimelineData.consumers[i].consumer_email +
            ">"
          ).trim();
          this.newTimelineData.consumerSelectionOptions.push({
            text: consumerDisplay,
            value: i,
          });
        }
        // sort it - backwards
        this.newTimelineData.consumerSelectionOptions.sort((a, b) => {
          return a.text.localeCompare(b.text, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        // this is faster than an unshift operation by a mile.
        this.newTimelineData.consumerSelectionOptions.push({
          text: "Select an existing consumer...",
          value: null,
        });
        this.newTimelineData.consumerSelectionOptions.reverse();
        this.newTimelineData.consumersAvailable = true;
      }
    } finally {
      this.loading = false;
      await this.searchTimelines("in_progress");
    }
  },
  computed: {
    ...mapGetters([
      "getTimelines",
      "getWorkflows",
      "getClientInContext",
      "getUserIsAdministrator",
      "getTimelineInContext",
      "getClientConsumerData",
      "getClients",
    ]),
  },
  methods: {
    paginationRange(start, end) {
      // used for pagination to overcome limitations of vue range
      if (start > end) {
        // do not show last page
        return [];
      }
      let rangeArray = [];
      do {
        rangeArray.push(start);
        start++;
      } while (start <= end);
      // return built range array
      return rangeArray;
    },
    setPage(page) {
      if (
        this.loading ||
        isNaN(page) ||
        page < 1 ||
        page > this.pagination.totalPages ||
        !Array.isArray(this.timelines)
      ) {
        return false;
      }
      let slice = page - 1;
      this.pagination.page = page;
      this.timelinesDisplayed = this.timelines.slice(
        slice * this.pagination.limit,
        (slice + 1) * this.pagination.limit
      );
    },
    resetForm() {
      this.newTimelineData.consumerSelected = null;
      this.form.consumerEmail = null;
      this.form.workflowUUID = null;
      if (
        this.getClientInContext.consumer_meta &&
        Array.isArray(this.metaDisplayOrder) &&
        this.metaDisplayOrder.length > 0
      ) {
        for (let i in this.metaDisplayOrder) {
          this.form.consumerMeta[this.metaDisplayOrder[i]] = {
            value: "",
            timeValue: null,
          };
        }
      }
    },
    initializeTimelineSetConsumer() {
      if (
        this.newTimelineData.consumerSelected === null ||
        isNaN(this.newTimelineData.consumerSelected)
      ) {
        return this.resetForm();
      }
      this.form.consumerEmail = this.newTimelineData.consumers[
        this.newTimelineData.consumerSelected
      ].consumer_email;
      if (
        this.getClientInContext.consumer_meta &&
        Array.isArray(this.metaDisplayOrder) &&
        this.metaDisplayOrder.length > 0
      ) {
        for (let i in this.metaDisplayOrder) {
          if (
            this.newTimelineData.consumers[
              this.newTimelineData.consumerSelected
            ]?.meta[this.metaDisplayOrder[i]]
          ) {
            if (
              this.getClientInContext.consumer_meta[this.metaDisplayOrder[i]]
                .type == "date" ||
              this.getClientInContext.consumer_meta[this.metaDisplayOrder[i]]
                .type == "date + time"
            ) {
              let date = new Date(
                this.newTimelineData.consumers[
                  this.newTimelineData.consumerSelected
                ]?.meta[this.metaDisplayOrder[i]] || null
              );
              if (date != "Invalid Date" && date !== null) {
                this.form.consumerMeta[this.metaDisplayOrder[i]] = {
                  value: date.toISOString().substr(0, 10),
                  timeValue:
                    this.getClientInContext.consumer_meta[
                      this.metaDisplayOrder[i]
                    ].type == "date + time"
                      ? `${date
                          .getHours()
                          .toString()
                          .padStart(2, "0")}:${date
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}:${date
                          .getSeconds()
                          .toString()
                          .padStart(2, "0")}`
                      : null,
                };
              } else {
                this.form.consumerMeta[this.metaDisplayOrder[i]] = {
                  value: "",
                  timeValue:
                    this.getClientInContext.consumer_meta[
                      this.metaDisplayOrder[i]
                    ].type == "date + time"
                      ? ""
                      : null,
                };
              }
            } else {
              this.form.consumerMeta[this.metaDisplayOrder[i]] = {
                value: this.newTimelineData.consumers[
                  this.newTimelineData.consumerSelected
                ]?.meta[this.metaDisplayOrder[i]],
                timeValue: null,
              };
            }
          } else {
            this.form.consumerMeta[this.metaDisplayOrder[i]] = {
              value: "",
              timeValue: null,
            };
          }
        }
      }
    },
    async setTimeline(timeline) {
      this.loading = true;
      try {
        let result = await this.$store.dispatch(
          "getTimeline",
          timeline.timeline_id
        );
        if (!result) {
          return this.$store.dispatch(
            "createErrors",
            "Could not load timeline, please try again later."
          );
        }

        let self = this;
        await this.$router
          .push({
            params: {
              timelineID: timeline.timeline_id,
            },
            name: "ClientTimelineOverview",
          })
          .catch(async (e) => {
            await self.searchTimelines("in_progress");
            self.$store.dispatch(
              "createErrors",
              "Could not load timeline, please try again later."
            );
          });
      } finally {
        this.loading = false;
      }
    },
    async createTimeline() {
      if (this.isSubmitting == (this.isSubmitting = true)) {
        return false;
      }
      // TODO: HELPER FUNCTION REALLOCATION
      let resolveValidDateTime = (function() {
        // Potential TODO: replace with client timezone when its added in, for now assumeoffsets from browser.
        let timezoneOffsetMin = new Date().getTimezoneOffset(),
          offsetStr = `${parseInt(Math.abs(timezoneOffsetMin / 60))
            .toString()
            .padStart(2, "0")}:${Math.abs(timezoneOffsetMin % 60)
            .toString()
            .padStart(2, "0")}`,
          timezoneStandard;

        // Add an opposite sign to the offset
        // If offset is 0, it means timezone is UTC
        if (timezoneOffsetMin < 0) {
          timezoneStandard = "+" + offsetStr;
        } else if (timezoneOffsetMin > 0) {
          timezoneStandard = "-" + offsetStr;
        } else if (timezoneOffsetMin == 0) {
          timezoneStandard = "Z";
        }
        // resolve date with timezone offset built in
        return (date, timeString) => {
          let time = timeString;
          if (!time) {
            time = "00:00:00";
          } else if (time.length == 5) {
            time += ":00";
          } else if (time.length != 8) {
            return false;
          }
          let iso8601 = `${date}T${time}${timezoneStandard}`;
          if (new Date(iso8601) == "Invalid Date") {
            return false;
          }
          return iso8601;
        };
      })();

      let hasError = false;
      this.$bvModal.hide("createNewTimeline");
      this.loading = true;
      try {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let errors = [];
        if (!this.form.workflowUUID) {
          errors.push("Please select a valid workflow.");
        }

        if (!this.form.consumerEmail || !re.test(this.form.consumerEmail)) {
          errors.push("Please add a valid e-mail.");
        }
        let consumerMeta = {};
        if (
          Array.isArray(this.metaDisplayOrder) &&
          this.metaDisplayOrder.length > 0
        ) {
          for (let i in this.metaDisplayOrder) {
            if (
              this.getClientInContext.consumer_meta[this.metaDisplayOrder[i]]
                .required &&
              !this.form.consumerMeta[this.metaDisplayOrder[i]].value
            ) {
              errors.push(`Please add a valid ${this.metaDisplayOrder[i]}`);
            } else if (this.form.consumerMeta[this.metaDisplayOrder[i]].value) {
              if (
                this.getClientInContext.consumer_meta[this.metaDisplayOrder[i]]
                  .type == "date" ||
                this.getClientInContext.consumer_meta[this.metaDisplayOrder[i]]
                  .type == "date + time"
              ) {
                let dateVal = resolveValidDateTime(
                  this.form.consumerMeta[this.metaDisplayOrder[i]].value,
                  this.form.consumerMeta[this.metaDisplayOrder[i]].timeValue
                );
                if (!dateVal) {
                  errors.push(
                    `Invalid value provided for ${this.metaDisplayOrder[i]}`
                  );
                }
                consumerMeta[this.metaDisplayOrder[i]] = dateVal;
              } else {
                //TODO Other validation here?
                consumerMeta[this.metaDisplayOrder[i]] = this.form.consumerMeta[
                  this.metaDisplayOrder[i]
                ].value;
              }
            }
          }
        }
        if (errors.length > 0) {
          hasError = true;
          return this.$store.dispatch("createErrors", errors);
        }

        let timeline = {
          clientID: this.getClientInContext.client_id,
          workflowUUID: this.form.workflowUUID,
          consumerEmail: this.form.consumerEmail,
          consumerMeta: consumerMeta,
        };

        // this aught to be the created timeline ID
        let createdTimelineResult = await this.$store.dispatch(
          "initializeTimeline",
          timeline
        );

        if (
          typeof createdTimelineResult == "object" &&
          createdTimelineResult.errors
        ) {
          hasError = true;
          return this.$store.dispatch(
            "createErrors",
            createdTimelineResult.errors
          );
        } else if (!createdTimelineResult || isNaN(createdTimelineResult)) {
          hasError = true;
          return this.$store.dispatch(
            "createErrors",
            "Failed to create timeline, please try again"
          );
        }

        this.$store.dispatch(
          "createAlerts",
          "Timeline has successfully been created"
        );
        let self = this;
        // timeline ID is coming from in context view
        await this.$router
          .push({
            params: {
              timelineID: createdTimelineResult,
            },
            name: "ClientTimelineOverview",
          })
          .catch(async (e) => {
            await self.searchTimelines("in_progress");
            self.$store.dispatch(
              "createErrors",
              "Could not load timeline, please try again later."
            );
          });
      } catch (e) {
        hasError = true;
        return this.$store.dispatch("createErrors", e.message);
      } finally {
        if (hasError) {
          this.$bvModal.show("createNewTimeline");
        } else {
          this.resetForm();
        }
        this.loading = false;
        this.isSubmitting = false;
      }
    },
    async searchTimelines(statusFilter) {
      // timelines are always filtered
      // TODO: Full Filtering
      if (this.loading == (this.loading = true)) {
        return false;
      }

      try {
        if (!statusFilter || !this.timelineStatusFilters[statusFilter]) {
          this.statusFilter = "all";
        } else {
          this.statusFilter = statusFilter;
        }
        await this.$store.dispatch("getAllTimelines");
        await this.$store.dispatch(
          "getActiveWorkflows",
          { clientid: this.getClientInContext.client_id }
        );

        for (let i in this.getWorkflows) {
          // only finalized workflows
          if (this.getWorkflows[i].workflow_finalized) {
            this.workflows.push({
              value: this.getWorkflows[i].workflow_uuid,
              text: this.getWorkflows[i].workflow_name,
            });
          }
        }

        let timelines = this.getTimelines;
        let filteredTimelines = [];
        this.loading = true;

        for (let i in timelines) {
          const timeline = timelines[i];
          if (!timeline.workflow_name) {
            if (this.getWorkflows) {
              const foundWorkflow = this.getWorkflows.find((workflow) => workflow.workflow_uuid === timeline.workflow_uuid);
              timeline.workflow_name = foundWorkflow?.workflow_name;
            }
          }

          if (statusFilter != null) {
            this.statusFilter = statusFilter;
            if (timelines[i].completed && statusFilter == "completed") {
              filteredTimelines.push(timelines[i]);
            } else if (
              !timelines[i].in_progress &&
              !timelines[i].completed &&
              statusFilter == "incomplete"
            ) {
              filteredTimelines.push(timelines[i]);
            } else if (
              timelines[i].in_progress &&
              statusFilter == "in_progress"
            ) {
              filteredTimelines.push(timelines[i]);
            } else if (statusFilter == "all") {
              filteredTimelines.push(timelines[i]);
            }
          }
        }

        this.timelines = statusFilter != null ? filteredTimelines : timelines;
        this.pagination.page = 1;
        this.pagination.totalPages = Math.ceil(
          this.timelines.length / this.pagination.limit
        );
        // page is now 0
        this.timelinesDisplayed = this.timelines.slice(
          0,
          this.pagination.limit
        );
      } finally {
        this.loading = false;
      }
    },
    async cancelTimeline() {
      // lazy abort call
      if (
        this.cancelTimelineObj &&
        this.cancelTimelineObj.current_action_id == 0
      ) {
        let result = await this.$store.dispatch("cancelTimeline", {
          timelineID: this.cancelTimelineObj.timeline_id,
        });
        this.cancelTimelineObj = null;
        if (!result || !result.success) {
          return this.$store.dispatch(
            "createErrors",
            result && result.message
              ? result.message
              : "There was an error cancelling this timeline"
          );
        } else {
          this.$store.dispatch(
            "createAlerts",
            "This timeline has been cancelled."
          );
          await this.searchTimelines(this.statusFilter);
        }
      } else {
        this.cancelTimelineObj = null;
      }
    },
    returnToDashboard() {
      if (this.getUserIsAdministrator) {
        this.$router
          .push({ name: this.getClientInContext ? "ClientInfo" : "Home" })
          .catch(() => {});
      } else {
        this.$router.push({ name: "Home" }).catch(() => {});
      }
    },
  },
};
</script>
